import axios from '@/axios.js'

export default {
    getFeatures: async (params, page = 1) => {
        try {
            let response = await axios.adminapi.get(`v4/authorization/features`,{
                params: {
                    ...params,
                    page: page
                }
            })
            return response.data.object
        } catch (error) {
            throw new Error(error)
        }
    },
    getScopes: async (params, page = 1) => {
        try {
            const response = await axios.adminapi.get(`v4/authorization/scopes`,{
                params: {
                    ...params,
                    page: page
                }
            })
            return response.data.object
        } catch (error) {
            throw new Error(error)
        }
    },
    getScope: async (scopeId) => {
        try {
            const response = await axios.adminapi.get(`v4/authorization/scopes/${scopeId}`)
            return response.data.object
        } catch (error) {
            throw new Error(error)
        }
    },
    createScope: async (data) => {
        try {
            const response = await axios.adminapi.post(`v4/authorization/scopes`, data)
            return response.data
        } catch (error) {
            throw new Error(error)
        }
    },
    editScope: async (scopeId, data) => {
        try {
            const response = await axios.adminapi.patch(`v4/authorization/scopes/${scopeId}`, data)
            return response.data
        } catch (error) {
            throw new Error(error)
        }
    },
    getRoles: async (params, page = 1) => {
        try {
            const response = await axios.adminapi.get(`v4/authorization/roles`,{
                params: {
                    ...params,
                    page: page
                }
            })
            return response.data.object
        } catch (error) {
            throw new Error(error)
        }
    },
    getRole: async (roleId) => {
        try {
            const response = await axios.adminapi.get(`v4/authorization/roles/${roleId}`)
            return response.data.object
        } catch (error) {
            throw new Error(error)
        }
    },
    createRole: async (data) => {
        try {
            const response = await axios.adminapi.post(`v4/authorization/roles`, data)
            return response.data
        } catch (error) {
            throw new Error(error)
        }
    },
    editRole: async (roleId, data) => {
        try {
            const response = await axios.adminapi.patch(`v4/authorization/roles/${roleId}`, data)
            return response.data
        } catch (error) {
            throw new Error(error)
        }
    },
    deleteRole: async (roleId) => {
        try {
            const response = await axios.adminapi.delete(`v4/authorization/roles/${roleId}`)
            return response.data
        } catch (error) {
            throw new Error(error)
        }
    },
    getScopeTypes: async () => {
        try {
            const response = await axios.adminapi.get(`v4/authorization/scope-types`)
            return response.data.object.data
        } catch (error) {
            throw new Error(error)
        }
    },
    getUserScopedRoles: async (userUuid) => {
        try {
            const response = await axios.adminapi.get(`v4/authorization/users/${userUuid}/scoped-roles`)
            return response.data.object.data
        } catch (error) {
            throw new Error(error)
        }
    },
    editUserScopedRoles: async (userUuid, data) => {
        try {
            const response = await axios.adminapi.patch(`v4/authorization/users/${userUuid}/scoped-roles`, data)
            return response.data
        } catch (error) {
            throw new Error(error)
        }
    },
}
